<template>
  <v-card>
    <v-card-title class="d-flex justify-content-between">
      <v-row>
        <v-col>
          <span> Exportação de vales </span>
        </v-col>

        <v-spacer></v-spacer>

        <v-col class="d-flex justify-end">
          <v-btn
            color="info"
            outlined
            @click="openModalFilter()"
          >
            <v-icon
              size="30"
              class="me-2"
            >
              {{ icons.mdiFilterPlus }}
            </v-icon>
            <span> Filtros </span>
          </v-btn>

          <v-btn
            class="ml-4"
            color="warning"
            outlined
            @click="exportCsv()"
          >
            <v-icon
              size="30"
              class="me-2"
            >
              {{ icons.mdiDownload }}
            </v-icon>
            <span> Exportar </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="itemsTable"
      :loading="isLoading"
      loading-text="Carregando dados..."
    >
      <template v-slot:no-data>
        <h3>Selecione um filtro</h3>
      </template>

      <template v-slot:item.value="item">
        {{ Number(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
      </template>

      <template v-slot:item.status="{ item }">
        <v-alert
          class="badge"
          text
          :color="
            item.status_leader === 'APROVADO' && !item.date_director
              ? 'success'
              : item.status_leader === 'REPROVADO'
                ? 'error'
                : item.status_director === 'APROVADO'
                  ? 'success'
                  : item.status_director === 'REPROVADO'
                    ? 'error'
                    : 'info'
          "
        >
          {{
            item.date_leader && !item.date_director
              ? `${item.status_leader} LÍDER`
              : item.date_director && item.status_financial
                ? item.status_financial
                : item.date_director
                  ? `${item.status_director} DIRETOR`
                  : 'SOLICITADO'
          }}
        </v-alert>
      </template>

      <template v-slot:item.description="{ item }">
        {{ item.description }}
      </template>

      <template v-slot:item.reference_date="{ item }">
        {{ dateFormat(item.reference_date) }}
      </template>

      <template v-slot:item.date_financial="{ item }">
        {{ dateFormat(item.date_financial) }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="showFilterModal"
      width="700px"
    >
      <FilterModal
        :event-list="eventList"
        :voucher-type-list="voucherTypeList"
        :companies="companies"
        @filteredData="getItemsTable($event)"
        @close="showFilterModal = false"
      ></FilterModal>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiAlert, mdiDownload, mdiEye, mdiFilterPlus,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import FilterModal from './FilterModal.vue'

const ExcelJS = require('exceljs')

export default {
  components: {
    FilterModal,
  },

  mixins: [formatters, messages],

  data() {
    return {
      dateMenu: '',
      lastStatus: '',
      dataDetails: '',
      employeeSelected: '',
      loadingCircleChat: '',
      hierarchicalProfile: '',
      employeeLeaderSelected: '',

      companies: [],
      dateRange: [],
      eventList: [],
      userProfile: [],
      listEmployees: [],
      voucherTypeList: [],

      headers: [
        {
          text: 'SOLICITANTE',
          value: 'requester',
          sortable: false,
          align: '',
        },
        {
          text: 'EVENTO',
          value: 'code_event',
          sortable: false,
          align: '',
        },
        {
          text: 'TIPO',
          value: 'description',
          sortable: false,
          align: '',
        },
        {
          text: 'LÍDER',
          value: 'leader',
          sortable: false,
          align: '',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'VALOR',
          value: 'value_paid',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DE REFERENCIA',
          value: 'reference_date',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      isLoading: false,
      showModal: false,
      showFilterModal: false,

      icons: {
        mdiFilterPlus,
        mdiDownload,
        mdiAlert,
        mdiEye,
      },
    }
  },

  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.listEmployees = localStorageSlim.get('listEmployees', { decrypt: true })
    this.hierarchicalProfile = this.userProfile.hierarchical_profile

    this.getEventList()
    this.getCompanyList()
    this.getVoucherTypesList()
  },

  methods: {
    async getItemsTable(event) {
      this.isLoading = true

      this.itemsTable = event
      this.isLoading = false
    },

    async getCompanyList() {
      await axiosIns
        .get('api/v1/records/company/index')
        .then(response => {
          this.companies = response.data.data
        })
        .catch(error => {
          this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
        })
    },

    async getVoucherTypesList() {
      await axiosIns
        .get('api/v1/integration/type_voucher/index')
        .then(response => {
          this.voucherTypeList = response.data.data
        })
        .catch(error => {
          this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
        })
    },

    async getEventList() {
      await axiosIns.get('api/v1/integration/event/index').then(response => {
        response.data.data.map(({
          id, name, code,
        }) => {
          this.eventList.push({
            id,
            name,
            code,
            description: `${code} - ${name}`,
          })
        })
      }).catch(error => {
        this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
      })
    },

    sumTotalAmountsPaid() {
      const tempObject = {}
      const newData = []
      const setNewData = []

      this.itemsTable.forEach(obj => {
        const previousValue = tempObject[obj.requester] || 0

        tempObject[obj.requester] = Number(previousValue) + Number(obj.value_paid)
      })

      const listWithAddedValues = Object.keys(tempObject).map(key => ({
        requester: key,
        value_paid: tempObject[key],
      }))

      this.itemsTable.map(item => {
        listWithAddedValues.map(object => {
          if (item.requester === object.requester) {
            newData.push({
              requester: object.requester,
              value: object.value_paid,
              cod_self: item.cod_self,
              code_event: item.code_event,
            })
          }
        })
      })

      newData.forEach(item => {
        const duplicated = setNewData.findIndex(redItem => item.requester === redItem.requester) > -1

        if (!duplicated) {
          setNewData.push(item)
        }
      })

      return setNewData
    },

    async exportCsv() {
      if (!this.itemsTable.length) return

      this.sumTotalAmountsPaid()

      const file = await this.getCsv()

      if (file) {
        saveAs(file)
      }
    },

    async getCsv() {
      this.loaderExport = true

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('vale')
      const dataFile = this.sumTotalAmountsPaid()

      worksheet.columns = [{ key: 'cod_self' }, { key: 'code_event' }, { key: 'value' }]

      // eslint-disable-next-line camelcase
      dataFile.map(({ cod_self, code_event, value }) => {
        worksheet.addRow([Number(cod_self), Number(code_event), Number(value)])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'vales_exportados', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })

      this.loaderExport = false

      return blob
    },

    openModalFilter() {
      this.showFilterModal = true
    },

    closeModal() {
      this.dataDetails = ''
      this.loadingCircleChat = ''
    },
  },
}
</script>

<style scoped>
.badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
