import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c(VRow,[_c(VCol,[_c('span',[_vm._v(" Exportação de vales ")])]),_c(VSpacer),_c(VCol,{staticClass:"d-flex justify-end"},[_c(VBtn,{attrs:{"color":"info","outlined":""},on:{"click":function($event){return _vm.openModalFilter()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilterPlus)+" ")]),_c('span',[_vm._v(" Filtros ")])],1),_c(VBtn,{staticClass:"ml-4",attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.exportCsv()}}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")]),_c('span',[_vm._v(" Exportar ")])],1)],1)],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.isLoading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('h3',[_vm._v("Selecione um filtro")])]},proxy:true},{key:"item.value",fn:function(item){return [_vm._v(" "+_vm._s(Number(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VAlert,{staticClass:"badge",attrs:{"text":"","color":item.status_leader === 'APROVADO' && !item.date_director
            ? 'success'
            : item.status_leader === 'REPROVADO'
              ? 'error'
              : item.status_director === 'APROVADO'
                ? 'success'
                : item.status_director === 'REPROVADO'
                  ? 'error'
                  : 'info'}},[_vm._v(" "+_vm._s(item.date_leader && !item.date_director ? ((item.status_leader) + " LÍDER") : item.date_director && item.status_financial ? item.status_financial : item.date_director ? ((item.status_director) + " DIRETOR") : 'SOLICITADO')+" ")])]}},{key:"item.description",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.description)+" ")]}},{key:"item.reference_date",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.reference_date))+" ")]}},{key:"item.date_financial",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.date_financial))+" ")]}}])}),_c(VDialog,{attrs:{"width":"700px"},model:{value:(_vm.showFilterModal),callback:function ($$v) {_vm.showFilterModal=$$v},expression:"showFilterModal"}},[_c('FilterModal',{attrs:{"event-list":_vm.eventList,"voucher-type-list":_vm.voucherTypeList,"companies":_vm.companies},on:{"filteredData":function($event){return _vm.getItemsTable($event)},"close":function($event){_vm.showFilterModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }