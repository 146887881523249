<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Filtrar vales
    </v-card-title>

    <v-card-text>
      <v-dialog
        ref="dialog"
        v-model="showCalendar"
        :return-value.sync="dateInput"
        width="400px"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedDateFormatted"
            :append-icon="icons.mdiCalendar"
            label="Mês"
            outlined
            readonly
            dense
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker
          v-model="dateInput"
          width="400px"
          locale="pt-BR"
          type="month"
          scrollable
          @dblclick:month="handleMonthSelection()"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="showCalendar = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(dateInput)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <v-autocomplete
        v-model="requesterNameSelected"
        :items="employeeList"
        item-text="name"
        item-value="name"
        label="Nome do solicitante"
        clearable
        outlined
        dense
      >
      </v-autocomplete>

      <v-autocomplete
        v-model="companyNameSelected"
        :items="companies"
        item-text="fantasy_name"
        item-value="fantasy_name"
        label="Filial"
        clearable
        outlined
        dense
      >
      </v-autocomplete>

      <v-autocomplete
        v-model="voucherTypeSelected"
        :items="voucherTypeList"
        item-text="description"
        item-value="id"
        label="Tipo de vale"
        clearable
        outlined
        dense
      >
      </v-autocomplete>

      <v-autocomplete
        v-model="eventIdSelected"
        :items="eventList"
        item-text="description"
        item-value="id"
        label="Evento"
        clearable
        outlined
        dense
      >
      </v-autocomplete>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendDataFromFilter()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'

export default {
  mixins: [formatters, messages],

  props: {
    eventList: {
      type: Array,
      required: true,
    },

    companies: {
      type: Array,
      required: true,
    },

    voucherTypeList: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dateInput: '',
      requesterNameSelected: '',
      companyNameSelected: '',
      voucherTypeSelected: '',
      eventIdSelected: '',

      isLoading: false,
      showModal: false,
      showCalendar: false,

      userProfile: [],
      employeeList: [],
      emptyValues: [],

      itemsTable: [],

      icons: {
        mdiCalendar,
      },
    }
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.dateInput)
    },
  },

  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.employeeList = localStorageSlim.get('listEmployees', { decrypt: true })
  },

  methods: {
    async sendDataFromFilter() {
      const {
        requesterNameSelected, companyNameSelected, dateInput, voucherTypeSelected, eventIdSelected,
      } = this
      const body = {
        users_name: requesterNameSelected,
        fantasy_name: companyNameSelected,
        reference: dateInput,
        type_voucher: voucherTypeSelected,
        events_id: eventIdSelected,
      }

      this.verifyRequisitionBody(body)

      if (this.requisitionBodyIsEmpty()) {
        this.showFilterEmpty()
        this.emptyValues = []

        return
      }

      this.isLoading = true

      await axiosIns.post('api/v1/integration/vale/report_vales', body).then(response => {
        this.itemsTable = response.data.data
        this.isLoading = false
      })

      if (!this.itemsTable.length) {
        this.showFilterError()

        return
      }

      this.$emit('filteredData', this.itemsTable)
      this.clearDataFilter()
    },

    handleMonthSelection() {
      this.$refs.dialog.save(this.dateInput)
      this.showCalendar = false
    },

    verifyRequisitionBody(body) {
      Object.values(body).map(value => {
        if (value === '') {
          this.emptyValues.push(value)
        }
      })
    },

    requisitionBodyIsEmpty() {
      if (this.emptyValues.length === 5) {
        return true
      }

      return false
    },

    showFilterError() {
      this.showMessage({
        icon: 'warning',
        title: 'Nenhuma informação encontrada!',
        text: 'Por favor, verifique os filtros utilizados!',
      })
    },

    showFilterEmpty() {
      this.showMessage({
        icon: 'warning',
        title: 'Filtro vazio!',
        text: 'Por favor, tente novamente!',
      })
    },

    formatDate(date) {
      if (!date) return null
      const [year, month] = date.split('-')

      return `${month}/${year}`
    },

    formatDateUSA(date) {
      if (!date) return null
      const [month, year] = date.split('/')

      return `${year}-${month}`
    },

    clearDataFilter() {
      this.dateInput = ''
      this.requesterNameSelected = ''

      this.closeModal()
    },
  },
}
</script>
